/*global jQuery*/
/**
 * QLEAVE
 * Global JS

 *
 * version: 1.0
 * author:  Squiz Australia
 */


/*
 * Table of Contents
 *
 * - Global
 *
 * ql namespace
 * ql.vars
 * ql.core
 * ql.data
 * ql.ajax
 * ql.utils
 * - Modules
 */
'use strict';

/*
--------------------
Global
--------------------
*/

var ql = (function () {
  /**
   *
   * Useful variables
   *
   */
  var vars = {
    'screensize': null, // 1 = mobile | 2 = tablet | 3 = desktop
    'categoryThemes': {
      'bc': '/building-and-construction',
      'cc': '/contract-cleaning',
      'cs': '/community-services'
    }
  };
  /**
   *
   * Core - Functions
   *
   * - core.init
   */
  var core = {
    'init': function () {
      /*
       *
       * Event listeners
       *
       */
      window.addEventListener('resize', viewportCheck);

      $('body').on('click', '.navigation__toggler', ql.nav.mobileNav);
      $('body').on('click', '.navigation__sub-toggler', ql.nav.mobileSubsToggle);
      // $('.desktop-nav .navigation__link').on('mouseenter', ql.nav.showDesktopMegaMenu);
      $('.desktop-nav .navigation__link-toggler').on('click', ql.nav.showDesktopMegaMenu);
      $('.desktop-nav .navigation__link-toggler').on('keypress', ql.nav.toggleDesktopMegaMenu);
      $('.desktop-nav .navigation__link-wrapper .navigation__link').on('focus', ql.nav.focusOutOfMenu);
      // $('.desktop-nav .navigation__link').on('focusout', ql.nav.focusOutOfMenu);
      // $('.desktop-nav .navigation__lvl-1 > .navigation__list-item').on('mouseleave', ql.nav.hideDesktopMegaMenu);
      $('body').on('click', ql.nav.closeDesktopMegaMenu);

      $('body').on('click', '.search-item', ql.search.searchDisplayToggle);
      $('body').on('click', '.login__toggler', ql.login.loginDisplayToggle);
      $('body').on('click', '.header .login-popup__close button', ql.login.loginHideToggle);
      // $('body').on('click', '.login-popup__bg', ql.login.loginHideToggle);
      // $('body').on('click', '.home-tabs__toggler, .home-tabs__group:not(".active") .home-tabs__tab, .home-tabs__group:not(".active") .home-tabs__image', ql.global.showHomeTab);
      $('body').on('click', '.home-tabs__toggler, .home-tabs__group:not(".active") .home-tabs__image', ql.global.showHomeTab);

      $('body').on('click', '.home-tabs__item-title a', ql.global.showTabAccordion);
      $('body').on('change', '#search-results-collection', ql.search.categoryChanged);
      $('body').on('keyup', '.price-format', ql.forms.formatPriceField);

      // test
      // hide elements when user clicks outside of them - like the search bar
      $(document).click(function (event) {
        if (!$(event.target).closest('.search').length) {
          if ($('.search').hasClass('active')) {
            $('.search').removeClass('active');
          }
        }
        if (!$(event.target).closest('.login-popup').length) {
          if ($('.login-popup').hasClass('active')) {
            $('.login-popup').removeClass('active');
            var body = $('body');
            var bg = body.find('.login-popup__bg');
            bg.remove();
          }
        }
      });

      viewportCheck();
      global.initSectionTheme();
      global.initCategoryTheme();
      global.homeBannerInit();
      global.homeTabInit();
      navigationAside.init();
      ql.global.contentAccordionInit();
      ql.global.newsCarouselInit();
      ql.global.notificationsInit();
      ql.global.contentTabsInit();
      ql.global.popUpSurvey();
      ql.nav.initDesktopNav();
      forms.matrixForms();
      ql.forms.addressAutocomplete();
    }
  }; /* end  const core */

  /**
   *
   * Data storage area
   *
   */
  var data = {

  }; /* end const user */
  /**
   *
   * Ajax call - returns a promise
   * params = {'url' : 'https://www.url.com.au/pathtoajaxrequest', 'data' : 'serialized Data string', 'callback': ql.ajax.success };
   *
   */
  var ajax = function (params) {
    if (typeof params.type == 'undefined') {
      params.type = "GET";
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function (e) {
      if (this.readyState === 4) {
        switch (this.status) {
          case 200:
            params.callback(this.responseText);
            break;
          default:
            //console.log("Error", this);
            if (typeof params.error != 'undefined') {
              params.error(this);
            }
            break;
        }
      }
    };
    xhttp.open(params.type, params.url, true);
    xhttp.send();
  };
  /**
   *
   * Core - Utility functions
   *
   * - utils.viewportChange()
   * - utils.setCookie(cname, cvalue, exdays)
   * - utils.getCookie(cname)
   * - utils.setLocalStorage()
   * - utils.getLocalStorage()
   */
  var utils = {
    // has the viewport changed?
    'viewportChange': function () {
      ql.responsiveImages($('.image-banner .active img'));
      ql.utils.mobileActions();
      ql.utils.tabletActions();
      ql.utils.desktopActions();
    },
    'mobileActions': function () {
      if (ql.vars.screensize == 1) {
        //console.log('Mobile');
        if ($('.inside-image-banner .tab-navigation').length > 0) {
          $('.inside-image-banner .tab-navigation').insertBefore('.landing-grid');
        }
      }
    },
    'tabletActions': function () {
      if (ql.vars.screensize == 2) {
        //console.log('Tablet');
        if ($('.inside-image-banner .tab-navigation').length == 0) {
          $('.tab-navigation').appendTo('.inside-image-banner');
        }
      }
    },
    'desktopActions': function () {
      if (ql.vars.screensize == 3) {
        //console.log('Desktop');
        $('.mobile-navigation').remove();
        $('.navigation__toggler').removeClass('active');
        $('.home-tabs').find('.collapsed').removeClass('collapsed');
        $('body').removeClass('body-scroll-off').removeClass('nav-active');
        if ($('.inside-image-banner .tab-navigation').length == 0) {
          $('.tab-navigation').appendTo('.inside-image-banner');
        }
      }
    },
    /**
     * Cookie functions - get|set|delete
     * as per https://www.w3schools.com/js/js_cookies.asp
     * @param - (str) 'cookie name', (str) 'cookie value', (int) expiry in days [optional]
     */
    'getCookie': function (cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    'setCookie': function (cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    'deleteCookie': function (name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    /**
     * Local Storage object
     */
    'getLocalStorage': function (name) {
      if (typeof (Storage) !== "undefined") {
        return localStorage.getItem(name);
      }
      return null;
    },
    'setLocalStorage': function (name, val) {
      var storeValue = val;
      if (typeof (storeValue) != 'string') {
        storeValue = JSON.stringify(val)
      }
      if (typeof (Storage) !== "undefined") {
        localStorage.setItem(name, storeValue);
      }
    },
    'deleteLocalStorage': function (name) {
      localStorage.removeItem(name);
    },
    /**
     * Session Storage objects - get|set|delete
     */
    'getSessionStorage': function (name) {
      if (typeof (Storage) !== "undefined") {
        return sessionStorage.getItem(name);
      }
      return "";
    },
    'setSessionStorage': function (name, val) {
      var storeValue = val;
      if (typeof (storeValue) != 'string') {
        storeValue = JSON.stringify(val)
      }
      if (typeof (Storage) !== "undefined") {
        sessionStorage.setItem(name, storeValue);
      }
    },
    'deleteSessionStorage': function (name) {
      sessionStorage.removeItem(name);
    }
  }; /* end  const utils */

  /**
   * Viewport change checker
   */
  var viewportCheck = debounce(function () {
    // the body tag index value reporesents the screen sizes
    var elementIndex = parseInt($('body').css('z-index'));
    if (elementIndex != ql.vars.screensize) {
      // viewport has changed
      ql.vars.screensize = elementIndex;
      ql.utils.viewportChange();
    }
  }, 150);

  var nav = {
    'mobileNav': function () {
      // if the mobile nav hasnt alreardy been cloned out
      if ($('.mobile-navigation').length == 0) {
        // add mobile nav ot dom
        //console.log('init mobile nav');
        var navClone = $('.navigation').clone()[0];
        var logoClone = $('.logo').clone()[0];
        var buttonClone = $('.header-mobile-options').clone()[0];
        var searchClone = $('.header .search').clone()[0];
        var siteLinksClone = $('.header .site-links__list').clone()[0];
        navClone.className = 'mobile-navigation';
        $(buttonClone).prependTo($(navClone));
        $(logoClone).prependTo($(navClone));


        $(searchClone).find('[id]').each(function () {
          $(this).attr('id', 'mobile__' + $(this).attr('id'));
        });
        $(searchClone).find('[for]').each(function () {
          $(this).attr('for', 'mobile__' + $(this).attr('for'));
        });
        $(searchClone).appendTo($(navClone));
        $(siteLinksClone).appendTo($(navClone));
        ql.nav.initAccordion($(navClone));
        $(navClone).insertAfter('.navigation');
        $('.navigation__toggler').addClass('active');
        // allow the css transition to work - needs a slight delay
        setTimeout(function () {
          $('.mobile-navigation').addClass('active');
          $('body').addClass('body-scroll-off nav-active');
          ql.search.initAutocomplete('.header .search-input');
        }, 1);
      } else {
        // mobile nav exists
        if ($('.mobile-navigation.active').length > 0) {
          $('.mobile-navigation').removeClass('active');
          $('.navigation__toggler').removeClass('active');
          $('body').removeClass('body-scroll-off').removeClass('nav-active');
        } else {
          $('.mobile-navigation').addClass('active');
          $('.navigation__toggler').addClass('active');
          $('body').addClass('body-scroll-off nav-active');
        }
      }

      return false;
    },
    'mobileSubsToggle': function () {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).parent().removeClass('active');
      } else {
        $(this).addClass('active');
        $(this).parent().addClass('active');
      }
      return false;
    },
    'initAccordion': function (navObj) {
      var baseNav = navObj;
      baseNav.find('.navigation__sub-list').each(function (i, item) {
        //console.log(item);
        var toggler = '<a href="#" class="navigation__sub-toggler">' +
          '<span class="visuallyhidden">Toggle menu</span>' +
          '<svg class="icon__open" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">' +
          '<path fill="#FFF" fill-rule="evenodd" d="M9.657 8.444H2a.99.99 0 0 1-1-.979.99.99 0 0 1 1-.978h7.586L6.707 3.67a.964.964 0 0 1 0-1.383 1.016 1.016 0 0 1 1.414 0l4.243 4.15c.06.059.11.122.15.19.291.17.486.482.486.838 0 .333-.17.627-.43.804a.976.976 0 0 1-.206.293l-4.243 4.151a1.016 1.016 0 0 1-1.414 0 .964.964 0 0 1 0-1.383l2.95-2.886z"/>' +
          '</svg>' +
          '</a>';
        $(this).parent().addClass('navigation__has-subs');
        $(toggler).appendTo($(this).parent());
      });
    },
    /**
     * Desktop nav functions
     */
    'initDesktopNav': function (e) {
      var nav = $('.desktop-nav');
      nav.find('.navigation__lvl-1 > li:not(".search__toggler")').each(function () {
        var lvl3Items = $(this).find('.navigation__lvl-3');
        if (lvl3Items.length == 0) {
          $(this).addClass('single_level-nav');
        }
      })
      // test
    },
    'showDesktopMegaMenu': function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $this = $(this);
      // var $parent = $this.parent('li');
      var $parent = $this.closest('li');
      var $linkWrapper = $this.parent('.navigation__link-wrapper');
      var isActive = $(this).hasClass('active');
      // remove sibling nav active display
      $parent.siblings('.active').find('.active').removeClass('active');
      $parent.siblings('.active').removeClass('active');
      // enable megamenu for hovered menu item
      if (!$parent.hasClass('navigation__list-item-home')) {
        if(!isActive) {
          $this.addClass('active');
          $linkWrapper.addClass('active');
          $parent.addClass('active');
          $('body').addClass('fade-bg');
          $parent.find('.navigation__lvl-2').addClass('active');
        } else {
          $this.removeClass('active');
          $linkWrapper.removeClass('active');
          $parent.removeClass('active');
          $('body').removeClass('fade-bg');
          $parent.find('.navigation__lvl-2').removeClass('active');
        }
      }
    },
    'toggleDesktopMegaMenu': function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (event.keyCode == 13 || event.keyCode == 32) {
        var $this = $(this);
        // var $parent = $this.parent('li');
        var $parent = $this.closest('li');
        var $linkWrapper = $this.parent('.navigation__link-wrapper');
        var isActive = $(this).hasClass('active');
        // remove sibling nav active display
        $parent.siblings('.active').find('.active').removeClass('active');
        $parent.siblings('.active').removeClass('active');
        // enable megamenu for hovered menu item
        if (!$parent.hasClass('navigation__list-item-home')) {
          if(!isActive) {
            $this.addClass('active');
            $linkWrapper.addClass('active');
            $parent.addClass('active');
            $('body').addClass('fade-bg');
            $parent.find('.navigation__lvl-2').addClass('active');
          } else {
            $this.removeClass('active');
            $linkWrapper.removeClass('active');
            $parent.removeClass('active');
            $('body').removeClass('fade-bg');
            $parent.find('.navigation__lvl-2').removeClass('active');
          }
        }
      }
    },
    'hideDesktopMegaMenu': function (e) {
      var $this = $(this);
      $this.removeClass('active');
      $this.find('.navigation__lvl-2').removeClass('active').find('a').attr('tabindex', '-1');
      $('body').removeClass('fade-bg');
    },
    'closeDesktopMegaMenu': function () {
      $('.desktop-nav').find('.active').removeClass('active');
      // $('.desktop-nav').find('.navigation__lvl-2').find('a').attr('tabindex', '-1');
      $('body').removeClass('fade-bg');         
    },
    'escapeOutOfMenu': function (e) {
      e.removeClass('active');
      e.find('.navigation__lvl-2').removeClass('active').find('a').attr('tabindex', '-1');
      // add focus to the next nav item
      e.next('li').find('.navigation__link').focus();
    },
    'focusOutOfMenu': function (e) {
      // setTimeout(function () {
        // if ($(document.activeElement).parents('.navigation').length == 0) {
          // $('.navigation__list-item .active').removeClass('active');
          // $('.navigation__list-item.active').removeClass('active');
          // $('body').removeClass('fade-bg');
          $('.navigation__list-item').siblings('.active').find('.active').removeClass('active');
          $('.navigation__list-item').siblings('.active').removeClass('active');
          $('body').removeClass('fade-bg');
      //   }
      // }, 1);
    }
  };
  var navigationAside = {
    'init': function init() {
      $('body').on('click', '.aside__navigation__toggler', navigationAside.toggleMenuItem);
      $('body').on('click', '.aside__navigation__mobile-toggler', navigationAside.toggleMobileAside);
      // initialise the nav ccordion structure
      navigationAside.initAsideNav();
    },
    'toggleMobileAside': function () {
      if (ql.vars.screensize < 3 && $('.main__aside.active').length == 0) {
        $('.main__aside').addClass('active');
        $('body').addClass('body-scroll-off');
      } else {
        $('.main__aside').removeClass('active');
        $('body').removeClass('body-scroll-off');
      }

      return false;
    },
    'toggleMenuItem': function toggleMenuItem(e) {
      if ($(this).parent('li').hasClass('active')) {
        $(this).removeClass('active');
        $(this).parent('li').removeClass('active');
        $(this).parent('li').find('> ul').addClass('aside__navigation__lvl-hidden');
      } else {
        $(this).addClass('active');
        $(this).parent('li').addClass('active');
        $(this).parent('li').find('> ul').removeClass('aside__navigation__lvl-hidden');
      }
      return false;

    },
    'initAsideNav': function initAsideNav() {
      var item = $('.aside__navigation__list');
      var currentItem = item.find('li.current');
      var currentParents = currentItem.parents('.hierarchy');

      // for standard inside pages - show a mobile Aside nav toggle option
      if ($('body.inside .main__aside .aside__navigation').length > 0) {
        // buttons
        var asideTogglerOpen = $('<a href="#" class="aside__navigation__mobile-toggler"><span class="fa fa-bars"></span> More information</a>');
        var asideTogglerClose = $('<a href="#" class="aside__navigation__mobile-toggler"><span class="fa fa-times"></span> Close menu</a>');
        if ($('body[class*="standard_"]').length > 0) {
          asideTogglerOpen.prependTo('.main__content');
          asideTogglerClose.prependTo('.main__aside');
        }
      }

      item.find('ul').each(function () {
        $(this).addClass('aside__navigation__lvl-hidden');
        var thisUL = $(this);
        thisUL.parent().addClass('navigation_has-subs');
        var toggler = '<a href="#" class="aside__navigation__toggler">' +
          '<span class="visuallyhidden">Toggle menu</span>' +
          '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">' +
          '<path fill="#4f4f50" fill-rule="evenodd" d="M9.657 8.444H2a.99.99 0 0 1-1-.979.99.99 0 0 1 1-.978h7.586L6.707 3.67a.964.964 0 0 1 0-1.383 1.016 1.016 0 0 1 1.414 0l4.243 4.15c.06.059.11.122.15.19.291.17.486.482.486.838 0 .333-.17.627-.43.804a.976.976 0 0 1-.206.293l-4.243 4.151a1.016 1.016 0 0 1-1.414 0 .964.964 0 0 1 0-1.383l2.95-2.886z"/>' +
          '</svg>' +
          '</a>';
        $(toggler).appendTo($(this).parent());

      });

      currentParents.each(function (i) {
        $(this).find('> .aside__navigation__toggler').trigger('click');
      });
      // if the current page has child pages, show them in the nav
      if (currentItem.find('ul').length > 0) {
        currentItem.find('> .aside__navigation__toggler').trigger('click');
      }
    }
  };

  var search = {
    'categoryChanged': function () {
      $(this).parents('form').submit();
    },
    'searchDisplayToggle': function () {
      $('.header .search').addClass('active');
      $('#search-query').focus();
      search.initAutocomplete('.header .search-input');
      return false;
    },
    'initAutocomplete': function (selector) {
      var search_form = $(selector).parents('form')[0];
      var form_selector = '#' + $(search_form).attr('id');
      var collection_id = $(search_form).find('input[name="collection"]').val();
      var suggest_json = $(search_form).find('input[name="suggest"]').val();

      var dataset_group = search.getAutocompleteDatasets(collection_id);

      $(selector).qc({
        program: suggest_json,
        alpha: '.5',
        show: '6',
        sort: '0',
        length: 3,
        horizontal: true,
        typeahead: {
          hint: false
        },
        datasets: dataset_group['datasets']
      });

      // Add a suggestions container to the autocomplete menu
      var className = selector.replace('.', '');

      // Add triangle at top of dropdown
      var triangle = '<div class="tt-triangle"></div>';
      if ($('.tt-triangle').length === 0) {
        $('.tt-menu').prepend(triangle);
      }

      // Move the datasets into the suggestions container
      var datasets = $(form_selector + ' .tt-dataset');
      datasets.each(function () {
        $(this).appendTo(selector + '__suggestions');
      });
    },
    'getAutocompleteDatasets': function (collection_id) {
      var datasets = {};
      var dataset_required = [];
      var dataset_map = {
        'bc': {
          'collection': 'qleave~ds-bc',
          'name': 'Building & Construction'
        },
        'cc': {
          'collection': 'qleave~ds-cc',
          'name': 'Contract Cleaning'
        },
        'cs': {
          'collection': 'qleave~ds-cs',
          'name': 'Community Services'
        },
        'about': {
          'collection': 'qleave~ds-about',
          'name': 'About Us'
        }

        // Figure out which datasets you need based on collection
      };
      switch (collection_id) {
        case 'qleave~sp-search':
          dataset_required = ['bc', 'cc', 'about'];
          break;
        case 'qleave~ds-bc':
          dataset_required = ['bc', 'about'];
          break;
        case 'qleave~ds-cc':
          dataset_required = ['cc', 'about'];
          break;
        case 'qleave~ds-cs':
          dataset_required = ['cs', 'about'];
          break;
      }

      // Construct the list of datasets required
      $(dataset_required).each(function (type_key, type_val) {

        datasets[type_val] = {
          'collection': dataset_map[type_val]['collection'],
          'format': 'extended',
          'template': {
            suggestion: function suggestion(data) {
              var category = data.category;
              var type = type_val;
              return search.renderResultSet(data);
            },
            header: search.renderDatasetHeader(dataset_map[type_val]['name']),
            notFound: search.renderNoResults()
          }
        };
      });

      return {
        'datasets': datasets,
      };
    },
    'renderNoResults': function () {
      return '';
    },
    'renderDatasetHeader': function (header_name) {
      return '<h5 class="tt-category"><span>' + header_name.toUpperCase() + '</span></h5>';
    },
    'renderResultSet': function (data) {
      var category = data.extra.cat;
      var action_link = data.extra.action;
      var suggestion = data.extra.disp;
      var search_form = $('.tt-open').parents('form')[0];
      var search_url = $(search_form).attr('action');
      var search_collection = $(search_form).find('input[name=collection]').val();
      var site_name = '| QLeave';

      // Search suggestion links should go to the search page as a typical search
      // Document links are modified to remove the "| Site Name"
      if (category != '') {
        suggestion = suggestion.replace(site_name, '');
      } else {
        action_link = search_url + '?query=' + suggestion + '&collection=' + search_collection;
      }

      // Render the result template
      var template = '<a href="' + action_link + '">' + suggestion + '</a>';
      var formattedResponse = $('<div class="tt-suggestion tt-selectable">').html(template);
      //console.log(formattedResponse);
      return formattedResponse;
    }
  };

  var login = {
    'loginDisplayToggle': function (e) {
      e.stopPropagation();
      var popup = $('.header .login-popup');
      if (!popup.hasClass('active')) {
        popup.addClass('active');
        var bg = $('<div></div>', {class:'login-popup__bg'});
        bg.addClass('login-popup__bg');
        var body = $('body');
        body.append(bg);
      }
      // var parent = $(this).parent('.login-item');
      // var popup = parent.find('.login-popup');
      // popup.addClass('active');
      // var bg = $('<div></div>', {class:'login-popup__bg'});
      // console.log(bg);
      // bg.addClass('login-popup__bg');
      // var body = $('body');
      // body.append(bg);
      return false;
    },
    'loginHideToggle': function () {
      var popup = $(this).closest('.login-popup');
      if (popup.hasClass('active')) {
        popup.removeClass('active');
        var body = $('body');
        var bg = body.find('.login-popup__bg');
        bg.remove();
      }
      return false;
    }
  };

  var global = {
    'initSectionTheme': function () {
      var currentPath = window.location.pathname;
      for (var category in ql.vars.categoryThemes) {
        if (currentPath.indexOf(ql.vars.categoryThemes[category]) != -1) {
          // we're on this section - so add a body class
          $('body').addClass('theme_' + category);
        }
      }
    },
    'initCategoryTheme': function () {
      // get category cookie
      var cookieVal = ql.utils.getCookie('qlcategory');
      var currentPath = window.location.pathname;

      if (cookieVal.length > 0) {
        for (var category in ql.vars.categoryThemes) {
          if (currentPath.indexOf(ql.vars.categoryThemes[category]) != -1) {
            if (cookieVal != category) {
              ql.utils.setCookie('qlcategory', category, 365);
            }
          }
        }
      } else {
        ql.utils.setCookie('qlcategory', 'default', 365);
      }
    },
    'homeBannerInit': function () {
      // check what category to initialise
      // get cookie value
      var cookieVal = ql.utils.getCookie('qlcategory');
      if (cookieVal != '') {
        $('.home [class*="image-banner__"]').removeClass('active');
        $('.home .image-banner__' + cookieVal).addClass('active');
      } else {
        $('.home [class*="image-banner__"]').removeClass('active');
        $('.home .image-banner__default').addClass('active');
      }
    },
    'homeTabInit': function () {
      $('.home-tabs').addClass('home-tabs__default');
      $('.home-tabs__item').each(function () {
        $(this).addClass('collapsed');
        $(this).find('.home-tabs__item-info');

      });
      var cookieVal = ql.utils.getCookie('qlcategory');
      if (cookieVal != '') {
        if (cookieVal == 'bc') {
          $('.home-tabs__bc').addClass('active');
          $('.home-tabs').removeClass('home-tabs__default');
          $('.home-tabs__cc, .home-tabs__cs').addClass('inactive');
        } else if (cookieVal == 'cc') {
          $('.home-tabs__cc').addClass('active');
          $('.home-tabs').removeClass('home-tabs__default');
          $('.home-tabs__bc, .home-tabs__cs').addClass('inactive');
        } else if (cookieVal == 'cs') {
          $('.home-tabs__cs').addClass('active');
          $('.home-tabs').removeClass('home-tabs__default');
          $('.home-tabs__bc, .home-tabs__cc').addClass('inactive');
        }
      }
    },
    'showHomeTab': function (e) {
      var $parent = $(this).parents('.home-tabs__group');

      var image = $parent.data('image-target');
      var category = $parent.data('category');


      if ($parent.hasClass('active')) {
        // hide
        $parent.removeClass('active');
        $('.home-tabs__group').removeClass('inactive');

        $('.image-banner__container.active').removeClass('active');
        $('.image-banner__default').addClass('active');
        $('.home-tabs').addClass('home-tabs__default');
        ql.responsiveImages($('.image-banner__default').find('img'));
        ql.utils.setCookie('qlcategory', 'default', 365);

      } else {
        //show
        // hide other open tab

        $('.home-tabs__group').removeClass('active').addClass('inactive');
        // show clicked tab
        $parent.addClass('active').removeClass('inactive');
        $('.home-tabs').removeClass('home-tabs__default');
        // deactivate industry specific banner image
        $('.image-banner__container.active').removeClass('active');
        // activate clicked industry specific banner image
        $(image).addClass('active');
        ql.responsiveImages($(image).find('img'));
        ql.utils.setCookie('qlcategory', category, 365);
      }

      return false;
    },
    'showTabAccordion': function (e) {
      if (ql.vars.screensize === 3) {
        // for desktop - when clicked, go to the page
        return;
      } else {
        var $parent = $(this).parents('.home-tabs__item');
        if ($parent.hasClass('collapsed')) {
          // hide
          $parent.removeClass('collapsed');
        } else {
          //show
          $parent.addClass('collapsed');
        }
        return false;
      }

    },
    'notificationsInit': function () {
      if ($('.notifications').length > 0) {

        // Set correct rode nodes for Notifications asset listing (depends on frontend lineage)
        // building & construction asset ID 230
        // contract cleaning  asset ID 231
        var root_nodes = "";
        var lineage = $('.notifications').data('lineage');
        if (lineage.indexOf("203") !== -1) {
          root_nodes = "516,515"; 
        } else if (lineage.indexOf("231") !== -1) {
          root_nodes = "517,515"; 
        } else {
          root_nodes = "515,516,517";
        }

        // Display the asset listing and initialise slick carousel
        var displayListing = function (notification_data) {
          $('.notifications').prepend(notification_data);
          var storage = ql.global.notificationStorageInit();
          $('.notifications__item').each(function () {
            var assetNum = $(this).data().asset;
            if (storage.indexOf(assetNum) !== -1) {
              $(this).remove();
            }
          });
          if ($('.notifications__item').length > 0) {
            $('.notifications-carousel').slick({
              prevArrow: $('.notifications-pagination-prev'),
              nextArrow: $('.notifications-pagination-next'),
              dots: true,
              dotsClass: 'notifications-pagination-counter',
              appendDots: $('.notifications-pagination-count'),
              customPaging: function (slider, i) {
                //console.log(slider);
                return (i + 1) + ' of ' + slider.slideCount;
              }
            });
          }
        };

        var params = {
          url: $('.notifications').data('assetListing') + "?root_nodes=" + root_nodes,
          callback: displayListing
        };

        // ajax in the notifications 
        // then show it to the user
        ql.ajax(params);

        $('body').on('click', '.notifications__close-item', ql.global.closeNotification);

      }

    },
    'closeNotification': function (e) {

      // Add asset number to local storage
      var visited = ql.global.notificationStorageInit();
      // console.log('Storage before deletion: ' + assets);
      var closedAsset = $(this).parents('.notifications__item').data().asset + ',';
      // console.log('Storage after deletion: ' + assets);
      ql.utils.setLocalStorage('qlNotifications', visited + closedAsset);

      // Remove asset from carousel and re-initialise
      $('.notifications-carousel').slick('unslick');
      $(this).parents('.notifications__item').remove();
      $('.notifications-carousel').slick({
        prevArrow: $('.notifications-pagination-prev'),
        nextArrow: $('.notifications-pagination-next'),
        dots: true,
        dotsClass: 'notifications-pagination-counter',
        appendDots: $('.notifications-pagination-count'),
        customPaging: function (slider, i) {
          //console.log(slider);
          return (i + 1) + ' of ' + slider.slideCount;
        }
      });
      return false;
    },
    'notificationStorageInit': function () {
      if (ql.utils.getLocalStorage('qlNotifications') === null) {
        //console.log('Initialising local storage');
        ql.utils.setLocalStorage('qlNotifications', "1,");
      }
      return ql.utils.getLocalStorage('qlNotifications');
    },
    'newsCarouselInit': function () {
      if ($('.ql__news-carousel').length > 0) {
        $('.ql__news-carousel').slick({
          prevArrow: $('.ql__news-pagination-prev'),
          nextArrow: $('.ql__news-pagination-next'),
          dots: true,
          dotsClass: 'ql__news-pagination-counter',
          appendDots: $('.ql__news-pagination-count'),
          fade: true,
          cssEase: 'linear',
          customPaging: function (slider, i) {
            //console.log(slider);
            return (i + 1) + ' of ' + slider.slideCount;
          }
        });
      }
    },
    /* Content accordions */
    'contentAccordionInit': function () {

      $('.content-accordion').each(function (i) {
        var $this = $(this);
        $this.addClass('content-accordion-enabled');
      });
      $('body').on('click', '.content-accordion__toggler', ql.global.contentAccordionToggle);

    },
    'contentAccordionToggle': function (e) {
      var $parent = $(this).parents('.content-accordion__item');

      if ($parent.hasClass('active')) {
        // hide
        $parent.removeClass('active');
      } else {
        //show
        $parent.addClass('active');
      }
      return false;
    },
    /* Content tabs */
    'contentTabsInit': function () {
      $('body').on('click', '.content-tabs__list a', ql.global.contentTabsToggle);

      $('.content-tabs').each(function (i) {
        var $this = $(this);
        var tabWrapper = '<ul class="content-tabs__list"></ul>';
        $(tabWrapper).prependTo($this);
        $this.addClass('content-accordion-enabled');

        $('.content-tabs__title').each(function (ind) {
          var tab = '<li><a href="#" data-target="' + ind + '">' + $(this).text() + '</a></li>';
          $(tab).appendTo($this.find('.content-tabs__list'));
        });
        $this.find('.content-tabs__list li:first a').trigger('click');
      });
    },
    'contentTabsToggle': function (e) {
      var $parent = $(this).parents('.content-tabs');
      var target = $(this).data('target');
      var selector = $parent.find('.content-tabs__item:eq(' + target + ')');

      if (selector.hasClass('active')) {
        // hide
        selector.removeClass('active');
        $(this).removeClass('active');
      } else {
        //show
        $parent.find('.active').removeClass('active');
        selector.addClass('active');
        $(this).addClass('active');
      }
      return false;
    },
    /*-- Pop up survey function for SDQLD-1528 --*/
    'popUpSurvey': function () {
      var $surveyPopup = $('.survey-popup'),
        // Added a timeout for Ticket SDQLD-1841
        $surveyContainer = $('.survey-popup__container'),
        iDelayTime = $surveyContainer.data('delay') !== null ? parseInt($surveyContainer.data('delay') + '000') : 40000,
        //Check if the cookie is already set
        checkCookie = ql.utils.getCookie('closedSurvey');

      //If not set display survey
      if (!checkCookie.length) {

        setTimeout(function () {
          $surveyPopup.addClass('active');
          $surveyPopup.show();
        }, iDelayTime);
      }
      //On close check and set cookie for a week
      $('.survey-popup__close-item').click(function () {
        if (!checkCookie.length) {
          ql.utils.setCookie('closedSurvey', 1, 7);
        }
        $('.survey-popup').hide();
      });

      //Submit the survey
      $('.survey-popup input[type="submit"]').click(function (e) {
        e.preventDefault();

        var matrixForm = $(this).closest('form');
        var container = matrixForm.parent();
        var formURL;
        //Check if there is a query
        if (window.location.search.length) {
          //if so append to query
          formURL = matrixForm.attr('action') + "&SQ_DESIGN_NAME=ajax";
        } else {
          //else append new query
          formURL = matrixForm.attr('action') + "?SQ_DESIGN_NAME=ajax";
        }

        var formSubmit = matrixForm.find('input[type="submit"]');
        var formData = matrixForm.serialize();
        formData += '&' + formSubmit.attr('id') + '=' + formSubmit.attr('value');

        // Process the form
        $.ajax({
          method: 'POST',
          url: formURL,
          data: formData,
          error: function (qxhr, status, errorThrown) {
            // Super good for for debugging
            console.log(qxhr);
            console.log(status);
            console.log(errorThrown);

            console.log('Sorry we are having trouble sending this form.');
          },
          success: function (response) {
            if ($('.survey-popup__container').length > 0) {
              //$('.survey-popup__container').addClass('show');
              $('.survey-popup__container').html(response);
            }
            if (!checkCookie.length) {
              ql.utils.setCookie('closedSurvey', 1, 7);
            }
          }

        });
      });
    }
  };

  var forms = {
    /**
     * Find all generic MAtrix forms, and adds a sq-form classname for various styling/functions
     */
    'matrixForms': function () {
      //console.log('init forms');
      /*
       *
       *  Matrix general form ID's to be included
       *
       */
      var formSelectors = 'form[id*="page_account_manager"],' +
        ' form[id*="page_asset_builder"],' +
        ' form[id*="search_page"],' +
        ' form[id*="form_email"],' +
        ' form[id*="login_form"],' +
        ' form[id*="page_password_reset"]';
      $(formSelectors).addClass('sq-form');

      /*
       *
       * find all submit buttons and wrap them in a shell to allow styling to match design
       * 
       */
      $('.sq-form').find('[type="submit"], [type="button"]').each(function () {
        $(this).wrap('<div class="input-button"></div>');
      });

      /*
       *
       *  Matrix dependentQuestions plugin 
       *
       */
      $('.sq-form').dependentQuestions({
        effect: "fade", // This can be 'fade' or 'slide'
        duration: "fast" // This can be 'slow', 'fast', or a number of milliseconds
      });

      ql.forms.formColumns();
      ql.forms.presetCategory();
      ql.forms.toggleFormSections();
      ql.forms.toggleFormSection();
      $('body').on('change', '.toggle-sections', ql.forms.toggleFormSections);
      $('body').on('change', '.toggle-section', ql.forms.toggleFormSection);
      /*
       *
       * For each form, initialise generic validation
       *
       */
      $('.sq-form').each(function (ind) {

        var thisForm = $(this);
        // 1st validate then ajax submit
        $(thisForm).validate({
          errorClass: 'sq-form-error',
          submitHandler: function (form) {
            form.submit();
          }
        });

      });
    },
    // sets a category specific preset
    'presetCategory': function () {
      var selector = $('.form-industry-default');
      var cookieVal = ql.utils.getCookie('qlcategory');
      if (cookieVal.length > 0) {
        if (cookieVal != 'default') {
          selector.each(function (ele) {
            $(this).val(cookieVal);
          });
        }
      }
    },
    'formatPriceField': function (event) {
      // format an input field to use commas when entering a price.
      // Eg: 10000000 becomes 10,000,000
      // reference https://webdesign.tutsplus.com/tutorials/auto-formatting-input-value--cms-26745

      // stop if user makes a selection within the input
      var selection = window.getSelection().toString();
      if (selection !== '') {
        return;
      }
      // or presses the arrow keys on the keyboard.
      if ($.inArray(event.keyCode, [38, 40, 37, 39]) !== -1) {
        return;
      }

      // Retrieve the value from the input. 
      var $this = $(this);
      var input = $this.val();

      // Sanitize the value using RegEx by removing unnecessary characters such as spaces, underscores, dashes, and letters.
      input = input.replace(/[\D\s\._\-]+/g, "");
      // make sure the value is an integer
      input = input ? parseInt(input, 10) : 0;
      // Add the thousand separator with the toLocaleString() function
      $this.val(function () {
        return (input === 0) ? "" : '$' + input.toLocaleString("en-US");
      });
    },
    'manualFormatPriceField': function (ele) {
      // Retrieve the value from the input. 
      var $this = $(ele);
      var input = $this.val();

      // Sanitize the value using RegEx by removing unnecessary characters such as spaces, underscores, dashes, and letters.
      input = input.replace(/[\D\s\._\-]+/g, "");
      // make sure the value is an integer
      input = input ? parseInt(input, 10) : 0;
      // Add the thousand separator with the toLocaleString() function
      $this.val(function () {
        return (input === 0) ? "" : '$' + input.toLocaleString("en-US");
      });
    },
    /*
     *
     * Check for column layout classes
     * 
     */
    'formColumns': function () {
      $('.sq-form').find('[class*="form-col-"]').each(function (ind) {
        var classNames = this.classList;
        var $this = $(this);
        for (var i = 0; i < classNames.length; i++) {
          if (classNames[i].indexOf('form-col-') > -1) {
            $this.parents('.sq-form-question').addClass(classNames[i]);
          }
          var clearDiv = $('<div class="form-clear-row"></div>');
          if (classNames[i].indexOf('clear-row-') > -1) {
            if (classNames[i] == 'clear-row-before') {
              clearDiv.insertBefore($this.parents('.sq-form-question'));
            }
            if (classNames[i] == 'clear-row-after') {
              clearDiv.insertAfter($this.parents('.sq-form-question'));
            }
          }
        }
      });
    },
    'formSubtraction': function (resultField) {
      var id = resultField.attr('id').split('_');
      var question = id[0];
      var outputId = id[1].slice(1); // ID of result field
      var input1Id = outputId - 2; // ID of 1st input
      var input2Id = outputId - 1; // ID of 2nd input
      var input1Val = $('#' + question + '_q' + input1Id).val(); // val of 1st input
      input1Val = parseInt(input1Val.replace(/,/g, '').replace(/\$/g, ''));
      if (input1Val == '' || input1Val == NaN) {
        input1Val = 0;
      }
      var input2Val = $('#' + question + '_q' + input2Id).val(); // val of 2nd input
      if (input2Val == '' || input2Val == NaN) {
        input2Val = 0;
      } else {
        input2Val = parseInt(input2Val.replace(/,/g, '').replace(/\$/g, ''));
      }
      var result = Math.abs(input1Val - input2Val); // Final result of subtraction

      //resultField.val(result); // Add result to the field
      result = result === 0 ? "" : '$' + result.toLocaleString("en-US");
      resultField.val(result);


      //ql.forms.manualFormatPriceField(resultField[0]);

    },
    'formAddition': function (resultField) {
      var id = resultField.attr('id').split('_');
      var question = id[0];
      var outputId = id[1].slice(1); // ID of result field
      var input1Id = outputId - 2; // ID of 1st input
      var input2Id = outputId - 1; // ID of 2nd input
      var input1Val = $('#' + question + '_q' + input1Id).val(); // val of 1st input
      input1Val = parseInt(input1Val.replace(/,/g, '').replace(/\$/g, ''));
      if (input1Val == '' || input1Val == NaN) {
        input1Val = 0;
      }
      var input2Val = $('#' + question + '_q' + input2Id).val(); // val of 2nd input
      input2Val = parseFloat(input2Val.replace(/,/g, '').replace(/\$/g, ''));
      if (input2Val == '' || input2Val == NaN) {
        input2Val = 0;
      }
      var result = Math.abs(input1Val * input2Val); // Final result of subtraction

      //resultField.val(result); // Add result to the field
      result = result === 0 ? "" : '$' + result.toLocaleString("en-US");
      resultField.val(result);

    },
    'addressAutocomplete': function () {
      var placeSearch;
      var autocomplete = {};
      var componentForm = {
        subpremise: 'short_name',
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        postal_code: 'short_name'
      };
      var input = document.getElementsByClassName('form-autocomplete');
      var forms = [];
      for (var i = 1; i <= input.length; i++) {
        forms.push('form-address-' + i);
      }

      $.each(forms, function (index, name) {
        autocomplete[name] = new google.maps.places.Autocomplete($('.' + name)[0], {
          types: ['geocode'],
          componentRestrictions: {
            country: 'au'
          }
        });
        google.maps.event.addListener(autocomplete[name], 'place_changed', function () {

          var id = index + 1;

          // Get the place details from the autocomplete object.
          var place = autocomplete[name].getPlace();

          for (var component in componentForm) {
            $(component).value = '';
            $(component).disabled = false;
          }

          var address = "";

          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            //console.log(addressType);
            var val = place.address_components[i][componentForm[addressType]];

            switch (addressType) {
              case "subpremise":
                //console.log(val);
                address = val + '/' + address;
                break;
              case "street_number":
                //console.log(val);
                address = address + val;
                break;
              case "route":
                address = address + ' ' + val;
                break;
              case "locality":
                var suburb = val;
                $('.form-suburb-' + id).val(suburb);
                break;
              case "administrative_area_level_1":
                var state = val;
                $('.form-state-' + id).val(state);
                break;
              case "postal_code":
                var postcode = val;
                $('.form-postcode-' + id).val(postcode);
                break;
            }
          }
          $('.' + name).val(address);
        });
      });
    },
    'geolocate': function () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          //autocomplete[item].setBounds(circle.getBounds());
        });
      }
    },
    'toggleFormSections': function () {
      $('.toggle-sections').each(function (i) {
        var checked = $(this).is(':checked');
        if (checked) {
          $('.toggle-section-' + $(this).val()).removeClass('hidden');
        } else {
          $('.toggle-section-' + $(this).val()).addClass('hidden');
        }
      });
    },
    'toggleFormSection': function () {
      $('.toggle-section').each(function (i) {
        var checked = $(this).is(':checked');
        if (checked) {
          $('.toggle-section-' + $(this).attr('id')).removeClass('hidden');
        } else {
          $('.toggle-section-' + $(this).attr('id')).addClass('hidden');
        }
      });
    }
  };

  return {
    vars: vars,
    core: core,
    data: data,
    ajax: ajax,
    utils: utils,
    viewportCheck: viewportCheck,
    nav: nav,
    navigationAside: navigationAside,
    search: search,
    login: login,
    global: global,
    forms: forms
  }
})(); /* end ql obj */

ql.responsiveImages = function (element) {
  var images = element;
  images.each(function () {
    var imageSource = '';
    if (ql.vars.screensize == 1) {
      imageSource = $(this).data('mobile');
    }
    if (ql.vars.screensize == 2) {
      imageSource = $(this).data('tablet');
    }
    if (ql.vars.screensize == 3) {
      imageSource = $(this).data('desktop');
    }
    //$(this).attr('src', imageSource);
    $('.image-banner').css('background-image', 'url(' + imageSource + ')');
  });
};

/*
 * Debounce function
 * https://davidwalsh.name/javascript-debounce-function
 */
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};



$(document).keyup(function (e) {
  if (e.keyCode == 27) { // escape key pressed
    // ql.nav.escapeOutOfMenu($('.navigation__list-item.active'));
    ql.nav.closeDesktopMegaMenu();
    if ($('.search.active').length > 0) {
      $('.search.active').removeClass('active');
    }
  }

});

/*
 * Launch! 
 */
(function () {
  $(document).ready(function () {
    ql.core.init();
  });
})();
/*
 * end Launch!
 */


/*
--------------------
Modules
--------------------
*/